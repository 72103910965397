<template>
    <!-- 定位面板 -->
    <div class="location_popover">
        <div class="header">
            <span>定位</span>
            <i
                class="el-icon-close"
                style="cursor: pointer"
                @click="closeLocation"
            ></i>
        </div>
        <div class="content">
            <div v-show="locationType == '2'" class="fpfont">
                <span>绘制类型:</span>
                <el-radio v-model="wktType" class="radio" label="1"
                    >点</el-radio
                >
                <el-radio v-model="wktType" class="radio" label="2"
                    >线</el-radio
                >
                <el-radio v-model="wktType" class="radio" label="3"
                    >面</el-radio
                >
            </div>
            <div v-show="locationType == '2'" class="fpcontent">
                <div class="part1">输入数据:</div>
                <el-input
                    v-show="wktType == '1'"
                    v-model="location_input"
                    placeholder="请输入经纬度坐标对，以空格分隔，例：112.0 35.0"
                    type="textarea"
                    style="width: 210px"
                    :autosize="{ minRows: 3, maxRows: 10 }"
                    :maxlength="500"
                    resize="none"
                >
                </el-input>
                <el-input
                    v-show="wktType == '2'"
                    v-model="location_input"
                    placeholder="请输入经纬度坐标对，经纬度以空格分隔，坐标对用逗号分隔，例：112.0 35.0,113.0 36.0"
                    type="textarea"
                    style="width: 214px"
                    :autosize="{ minRows: 3, maxRows: 10 }"
                    :maxlength="500"
                    resize="none"
                >
                </el-input>
                <el-input
                    v-show="wktType == '3'"
                    v-model="location_input"
                    placeholder="请输入经纬度坐标对，经纬度以空格分隔，坐标对用逗号分隔，例：112.0 35.0,114.0 38.0,121.0 41.0,112.0 35.0"
                    type="textarea"
                    style="width: 214px"
                    :autosize="{ minRows: 3, maxRows: 10 }"
                    :maxlength="500"
                    resize="none"
                >
                </el-input>
            </div>
            <div class="btn flex-end">
                <div
                    type="primary"
                    class="btnItem"
                    size="small"
                    @click="drawFeature"
                >
                    绘制
                </div>
                <div
                    type="primary"
                    class="btnItem"
                    size="small"
                    @click="clearFeature"
                >
                    清除
                </div>
                <div
                    type="primary"
                    class="btnItem"
                    size="small"
                    @click="closeLocation"
                >
                    取消
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import parse from "wellknown";
export default {
    name: "location",
    props: {
        basicMap: {
            type: Object,
            default: () => {},
        },
        currentTool: String,
    },
    data() {
        return {
            locationType: "2",
            wktType: "1",
            location_input: "",
            drawFeatureGroup: null,
        };
    },
    computed: {},
    mounted() {},
    methods: {
        closeLocation() {
            this.$emit("update:currentTool", "");
        },
        drawFeature: function () {
            // debugger;
            if (this.wktType == 1) {
                this.location_input = "POINT(" + this.location_input + ")";
            } else if (this.wktType == 2) {
                this.location_input = "LINESTRING(" + this.location_input + ")";
            } else if (this.wktType == 3) {
                this.location_input = "POLYGON((" + this.location_input + "))";
            }
            if (!this.drawFeatureGroup) {
                this.drawFeatureGroup = new L.featureGroup();
                this.drawFeatureGroup.addTo(this.basicMap);
            }
            if (this.locationType == "1") {
            } else {
                let wktDate = this.location_input;
                let geoJsonFromWKT = parse(wktDate);
                if (!geoJsonFromWKT) {
                    this.$message({
                        showClose: true,
                        message: "输入的WKT格式不正确，请重新输入！",
                        type: "warning",
                    });
                    this.location_input = "";
                } else {
                    // debugger;
                    let wktLayer = L.geoJson(geoJsonFromWKT, {
                        pointToLayer: function (geoJsonPoint, latlng) {
                            let myIcon = L.icon({
                                iconUrl: require("@image/map/mapImg/mapTool/marker-icon.png"),
                                iconSize: [25, 41],
                                iconAnchor: [12, 41],
                                popupAnchor: [0, -41],
                            });
                            return L.marker(latlng, {
                                icon: myIcon,
                            });
                        },
                    }).addTo(this.drawFeatureGroup);
                    this.basicMap.fitBounds(wktLayer.getBounds());
                }
            }
        },
        clearFeature: function () {
            if (this.drawFeatureGroup) {
                this.drawFeatureGroup.clearLayers();
            }
            this.location_input = "";
        },
    },
};
</script>
<style scoped lang="scss">
.location_popover {
    position: absolute;
    right: 45px;
    top: 60px;
    width: 300px;
    background: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2), 0 -1px 0px rgba(0, 0, 0, 0.02);
    z-index: 999;
    /*display: none;*/
}

.location_popover .content {
    text-align: left;
    min-height: 150px;
}

.location_popover .content .item {
    height: 25px;
}

.location_popover .content .item img {
    margin: 0px 10px;
    cursor: pointer;
    float: left;
}

.location_popover .content .item div {
    margin: 3px 0px;
    float: left;
}

.location_popover .content .btn {
    float: right;
    margin: 10px;
    .btnItem {
        width: 56px;
        height: 32px;
        background: #35507c;
        color: #a8c8ff;
        padding: 9px 15px;
        font-size: 12px;
        border-radius: 3px;
        cursor: pointer;
        margin-right: 10px;
    }
}

.location_popover .content .fpfont {
    margin: 10px;
}

.location_popover .content .fpfont span {
    margin-right: 10px;
    font-size: 13px;
    color: black;
}

.location_popover .content .fpcontent {
    height: 115px;
    margin: 0px 10px;
}

.location_popover .content .fpcontent .part1 {
    color: black;
    font-size: 13px;
    float: left;
    height: 100%;
    margin-right: 10px;
}
.header {
    background: #4285f4;
    background: #1a3059;
    height: 30px;
    line-height: 30px;
    font-size: 13px;
    text-align: left;
    color: #fff;
    font-weight: bold;
    padding: 0px 10px;
}

.header i {
    position: absolute;
    right: 10px;
    top: 9px;
    font-size: 12px;
}
</style>